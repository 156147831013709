import React from 'react'

function GraphLoader (props) {
	return (
		<div className="graph-loader-container">
			<div className="loader loader-7">
				<div className="line line1"></div>
				<div className="line line2"></div>
				<div className="line line3"></div>
			</div>
		</div>
	)
}

export default GraphLoader
