import React from 'react'

export const getStats = (data) => {
	const numOfBlocks = data.blocks.length;
	let _blocks = 'blocks';
	let _houses = 'houses';
	let housesPerBlock = [];
	let numOfHouses = [];

	if (data.blocks.length <= 0) {
		return null
	} else {
		for (let i = 0; i < numOfBlocks; i++) {
			// If length equals 1 then no plural word
			if (data.blocks[i].houses.length === 1) {
				_houses = 'house'
			}

			housesPerBlock.push(
				<React.Fragment key={data.name}>
					{`Block ${data.blocks[i].name} has ${data.blocks[i].houses.length} ${_houses}.`}
				</React.Fragment>,
			);
			numOfHouses.push(data.blocks[i].houses.length)
		}
		if (numOfBlocks === 1) {
			_blocks = 'block'
		} else {
			_blocks = 'blocks'
		}

		let totalBlocks = `This file contains ${numOfBlocks} ${_blocks}.`;

		if (Math.max(...numOfHouses) === 1) {
			_houses = 'house'
		} else {
			_houses = 'houses'
		}
		let maxHouses = `Each block has a maximum number of ${Math.max(...numOfHouses)} ${_houses} per row.`;

		if (Math.min(...numOfHouses) === 1) {
			_houses = 'house'
		} else {
			_houses = 'houses'
		}
		let minHouses = `Each block has a minimum number of ${Math.min(...numOfHouses)} ${_houses} per row.`;

		if (numOfHouses.reduce(
			(partial_sum, a) => partial_sum + a, 0) === 1) {
			_houses = 'house'
		} else {
			_houses = 'houses'
		}

		let totalHouses = `This file contains a total number of ${numOfHouses.reduce(
			(partial_sum, a) => partial_sum + a, 0)} ${_houses}.`;

		let blockStats = [totalBlocks, housesPerBlock, minHouses, maxHouses, totalHouses];

		return blockStats
	}
};
