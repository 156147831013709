import React, {Component} from 'react';

class Spinner extends Component {
    render() {
        return (
            <div className="lazy-loader-container">
                <div className="lazy-loader"> </div>
                <p>Fetching configurations...</p>
            </div>
        );
    }
}

export default Spinner;
