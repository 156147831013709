import React, {Component} from "react";

class Footer extends Component {
	render() {
		return (
			<div className="footer">
					<div className="">
						{/*<img className="footer-logo" src="https://asrr.nl/favicon.ico" alt="logo"/>*/}
					</div>
					<div className="full-width-900 blue">
						Design &copy;2019 <a href="https://asrr.nl" className="blue">ASRR Tech</a>, All rights reserved.
					</div>
			</div>
		);
	}
}

export default Footer;