import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GraphLoader from "./GraphLoader";

function CustomButton(props) {
    return (
        <div
            className={props.disabled ? `action-button disabled ${props.buttonType}` : `action-button ${props.buttonType}`}
            onClick={props.onClick}>
            {props.downloading ? <GraphLoader/> : <FontAwesomeIcon icon={props.icon}/>}
            {props.popup == null ? null : (<div className="popup"><p>{props.popup}</p></div>)}
        </div>
    );
}

export default CustomButton;
