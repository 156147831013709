import React, {Component} from 'react'
import {Button, Checkbox, Divider, Form, Grid, Header, Icon, Image, Progress, Segment} from 'semantic-ui-react'
import Files from 'react-files'
import logo from '../Images/logo-transparant.png'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'

class Import extends Component {
    constructor(props) {
        super(props);
        this.fileReader = new FileReader();
        this.state = {
            percent: 0,
            submitted: false,
            requestId: '',
            status: '',
            show: false,
            data: [],
            dataLoaded: false,
        };

        this.fileReader.onload = (event) => {
            this.setState({jsonFile: JSON.parse(event.target.result)}, () => {
                console.log(JSON.parse(event.target.result));
                this.importConfiguration(this.state.jsonFile);
                this.setState({percent: 15, status: 'FILES UPLOADING...'})
            });
            this.setState({data: this.state.jsonFile});
            this.setState({dataLoaded: true});
            this.setState({show: true})
        }
    }

    importConfiguration = (projectJson) => {
        let self = this;

        fetch(process.env.REACT_APP_API_BASE_URL, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                    exportRevitFile: true,
                    options: projectJson,
                    projectId: "",
                    projectName: projectJson.name,
                    source: 'Dashboard Import',
                    type: "project"
                },
            ),
        }).then(function (response) {
            if (!response.ok) {
                console.log('error', response);
                throw new Error(response.statusText)
            }
            return response.json()
        }).then(
            data => {
                self.setState({submitted: true, requestId: data.configurationId});

                self.interval = setInterval(() => {
                    this.fetchStatus(data.configurationId);
                    return this.setState({time: Date.now()})
                }, 5000)
            },
        ).catch(error => console.log(error.body))
    };

    fetchStatus = (id) => {
        let self = this;
        fetch(process.env.REACT_APP_API_BASE_URL + '/' + id + '/status', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }).then(function (response) {
            if (!response.ok) {
                console.log('error', response);
                throw new Error(response.statusText)
            }
            return response.json()
        }).then(
            data => {
            	console.log(data);
                let status = data.status;
                if(status === 'CONFIGURATION_FAILED'){
                    self.setState({
                        percent:0,
                        status:`${status}`
                    })
                }
                if (status === 'QUEUED') {
                    self.setState({
                        percent: 25,
                        status: `${status}, ${data.queuePosition} ${data.queuePosition > 1 ? "files" : "file"} 
                    before you.`
                    })
                } else if (status === 'PROCESSING') {
                    self.setState({percent: 60, status})
                } else if (status === 'FINISHED') {
                    self.setState({percent: 100, status})
                }
            },
        ).catch(error => console.log(error.body))
    };

    getExport = (id, fileType, extension) => {
        window.open(process.env.REACT_APP_AZURE_BLOB_URL + `/${fileType}/${id}.${extension}`, '_blank');
        window.focus();
    };
    
    increment = () =>
        this.setState({
            percent: this.state.percent >= 100 ? 0 : this.state.percent + 20,
        });

    getRevitFile = () => {
        window.open(`/export.zip`, '_blank');
        window.focus()
    };

    getStats = (data) => {
        if (this.state.dataLoaded) {
            const numOfBlocks = data.blocks.length;
            let _blocks = 'blocks';
            let _houses = 'houses';
            let housesPerBlock = [];
            let numOfHouses = [];


            for (let i = 0; i < numOfBlocks; i++) {
                // If length equals 1 then no plural word
                if (data.blocks[i].houses.length === 1) {
                    _houses = 'house'
                }

                housesPerBlock.push(
                    <React.Fragment key={data.name}>
                        <li>{`Block ${data.blocks[i].name} has ${data.blocks[i].houses.length} ${_houses}.`}</li>
                    </React.Fragment>,
                );
                numOfHouses.push(data.blocks[i].houses.length)
            }
            if (numOfBlocks === 1) {
                _blocks = 'block'
            } else {
                _blocks = "blocks"
            }

            let totalBlocks = `This file contains ${numOfBlocks} ${_blocks}.`;

            if (Math.max(...numOfHouses) === 1) {
                _houses = "house"
            } else {
                _houses = "houses"
            }
            let maxHouses = `Each block has a maximum number of ${Math.max(...numOfHouses)} ${_houses} per row.`;

            if (Math.min(...numOfHouses) === 1) {
                _houses = "house"
            } else {
                _houses = "houses"
            }
            let minHouses = `Each block has a minimum number of ${Math.min(...numOfHouses)} ${_houses} per row.`;

            if (numOfHouses.reduce(
                (partial_sum, a) => partial_sum + a, 0) === 1) {
                _houses = "house"
            } else {
                _houses = "houses"
            }

            let totalHouses = `This file contains a total number of ${numOfHouses.reduce(
                (partial_sum, a) => partial_sum + a, 0)} ${_houses}.`;

            let blockStats = {totalBlocks, housesPerBlock, minHouses, maxHouses, totalHouses};
            let stats = [{blockStats}];
            return stats
        }

    };

    render() {
        const {percent} = this.state;
        // // Returns parsed data from the getStats function
        // const parsedData = this.getStats(this.state.data);
        // //Creates function which maps the stats
        // const showStats = () => {
        // 	if (this.state.dataLoaded) {
        // 		return <div>{parsedData.map((d) => {
        // 			return <Fade key={this.state.data.name} duration={2000} when={this.state.show}>
        // 				<ul className="list-items-stats">
        // 					<li>{d.blockStats.totalBlocks}</li>
        // 					{d.blockStats.housesPerBlock}
        // 					<li>{d.blockStats.totalHouses}</li>
        // 					<li>{d.blockStats.minHouses}</li>
        // 					<li>{d.blockStats.maxHouses}</li>
        // 				</ul>
        // 			</Fade>
        // 		})}
        // 		</div>
        // 	}
        // };

        return (
            <div className="white">
                <Grid verticalAlign='middle' stackable textAlign='center'>
                    <Grid.Row style={{height: '60vh'}}>
                        <Grid.Column style={{maxWidth: '800px', marginTop:"20px"}}>
                            <Segment className="drop-shadow">
                                <Image size="small" src={logo} style={{margin: 'auto'}}/>
                                <Divider/>
                                <Files
                                    className="files-dropzone white"
                                    onChange={file => {
                                        this.fileReader.readAsText(file[0])
                                    }}
                                    onError={err => console.log(err)}
                                    accepts={['.json']}
                                    multiple
                                    maxFiles={3}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    <Segment placeholder>
                                        <Header as='h4' icon>
                                            <Icon name='file outline'/>
                                            Import JSON
                                        </Header>
                                        <Button secondary className="add-file-button">Add file</Button>
                                    </Segment>
                                </Files>
                                <Divider/>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Checkbox label='.rvt file' defaultChecked/>
                                        </Form.Field>
                                        <Form.Field>
                                            <Checkbox label='.pdf file'/>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                                <div style={{display: this.state.percent === 0 ? 'none' : ''}}>
                                    <Divider/>
                                    <Progress percent={this.state.percent} label={this.state.status} indicating/>
                                    <Divider/>
                                </div>

                                <div style={{display: this.state.percent === 100 ? '' : 'none'}}>
                                    <Button
                                        onClick={() => this.getExport(this.state.requestId, "rvt-collection", "zip")}
                                        secondary
                                    >Download</Button>
                                </div>

                                <Message hidden={!this.state.submitted}>Configuration is being submitted with
                                    requestId <b>{this.state.requestId}</b>

                                </Message>
                                <Message icon>

                                    <Message.Content>
                                        <Message.Header>Configuration stats</Message.Header>
                                        {/*{showStats()}*/}
                                    </Message.Content>
                                </Message>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default Import
