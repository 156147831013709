import React, {PureComponent} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle, faPaste, faRedo, faSearch, faStepForward, faDownload} from '@fortawesome/free-solid-svg-icons'
import {Button, Divider, Grid, Icon, Message, Modal, Pagination, Progress, Segment} from 'semantic-ui-react'
import CustomButton from './CustomButton'
import {getStats} from './Functions';
import {CopyToClipboard} from "react-copy-to-clipboard";
import Spinner from "./Spinner";

class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: this.props.pageNumber,
            totalPageNumber: 0,
            configurations: [],
            time: Date.now(),
            loadingEntries: true,
            currentId: '',
            renderData: "all",
            paginationOptions: {boundaryRange: 10, showPreviousAndNextNav: true, siblingRange: 1},
            activeIndex: "",
            totalPercentage: '',
            subPercentage: "",
            activeConfiguration: "",
            configurationStatus: "",
            configurationCaption: "",
            configuration: [],
            downloadID: '',
            downloading: [],
            information: '',
            openInformation: false,
            exception: '',
            uploadError_Message: [],
            configurationError_Message: {},
            statsArray: [],
            json: '',
            download: {
                open:false,
                id:"",
                name:"",
                files:[]
            }
        }
    }

    openList = (index) => {
        (this.state.activeIndex === index ? this.setState({activeIndex: null}) : this.setState(
            {activeIndex: index}))
    };

    fetchConfig() {
        let self = this;
        fetch(process.env.REACT_APP_API_BASE_URL + '/page/' + (this.props.pageNumber - 1))
            .then(response => response.json())
            .then(function (data) {
                self.setState({configurations: data.configurations});
                self.setState({totalPageNumber: data.totalPageCount});
                self.setState({loadingEntries: false})
            })
            .catch(error => console.log(error))
    }

    fetchOptions = (id, _callback, index, endpoint) => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/${id}/${endpoint}`,
        ).then((response) => response.json()).then((data) => {
                _callback(data, index)
            },
        ).catch((error => console.log(error)))
    };

    handleRetry = (id, exportRevitFile, asNewRequest) => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/${id}/retry`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id: id, exportRevitFile: exportRevitFile, asNewRequest: asNewRequest}),
        }).then(function (response) {
            if (!response.ok) {
                console.log('error', response);
                throw new Error(response.statusText)
            }
        }).catch(error => console.log(error));

        this.closeRetryModal();
        this.fetchConfig()
    };

    handleSkip = (id, skipMessage) => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/' + id + '/skip', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id: id, message: skipMessage}),
        }).then(function (response) {
            if (!response.ok) {
                console.log('error', response);
                throw new Error(response.statusText)
            }
        }).catch(error => console.log(error));
        console.log('skipped');
        this.closeSkipModal();
        this.fetchConfig()
    };

    getExports = (id, name) => {
        let self = this;
        this.setState({
            download:{
                ...this.state.download,
                open:true
            }
        })
        this.setState({downloading: this.state.downloading.concat(id)});
        fetch(`${process.env.REACT_APP_API_BASE_URL}/${id}/exports`, {}).then(function (response) {
            if (!response.ok) {
                console.log('error', response);
                self.setState({downloading: self.state.downloading.splice(self.state.downloading.indexOf(id))})
                throw new Error(response.statusText)
            }
            if (response.ok) {
                self.setState({downloading: self.state.downloading.splice(self.state.downloading.indexOf(id))}, () => {
                    console.log(response.json().then(data => {
                        self.setState({
                            download:{
                                ...self.state.download,
                                files:data
                            }
                        })
                    }));
                })

            }

        }).catch(error => console.log(error))
    };

    state = {openRetryModal: false, id: '', exportRevitFile: '', asNewRequest: '', message: ''};

    showRetryModal = (id, exportRevitFile, asNewRequest, message) => () => this.setState({
        id: id,
        exportRevitFile: exportRevitFile,
        asNewRequest: asNewRequest,
        message: message,
        openRetryModal: true,
    });

    closeRetryModal = () => this.setState({openRetryModal: false});

    showSkipModal = (id, skipMessage, message) => () => this.setState({
        id: id,
        skipMessage: skipMessage,
        message: message,
        openSkipModal: true,
    });
    closeSkipModal = () => this.setState({openSkipModal: false});

    showRequestModal = (request) => {
        this.setState({
            json: JSON.stringify(request, null, 4),
            showRequest: true,
        })
    };

    closeRequestModal = () => this.setState({showRequest: false});

    // showDownloadModal = (configID, name) => {
    //     {
    //         this.setState({
    //             download: {
    //                 open:true,
    //                 id:configID,
    //                 name:name
    //             }
    //         })
    //     }
    // };

    closeDownload = () => this.setState({download: {...this.state.download, open:false}});


    handlePaginationChange = (e, {activePage}) => {
        window.location.href = '/dashboard/' + activePage
        return this.setState({activePage})
    };

    showInfo = (id, index) => {
        //If config is busy uploading, hide stats without collapsing row
        if (this.state.activeConfiguration === id && this.state.informationState === "info") {
            this.setState({informationState: null})
        } else {
            this.setState({
                currentId: id,
                informationState: 'info',
                openInformation: (!this.state.openInformation || id !== this.state.currentId) ||
                    this.state.informationState !== 'info',
            });
        }
        this.fetchOptions(id, this.printInfo, index, 'options')
    };

    showUploadError = (id, index) => {
        this.setState({
            currentId: id,
            informationState: 'uploadError',
            openInformation: (!this.state.openInformation || id !== this.state.currentId) ||
                this.state.informationState !== 'uploadError',
        });
        this.fetchOptions(id, this.printUploadError, index, 'upload-exception')
    };

    showConfigurationError = (id, index) => {
        this.setState({
            currentId: id,
            informationState: 'configurationError',
            openInformation: (!this.state.openInformation || id !== this.state.currentId) ||
                this.state.informationState !== 'configurationError',
        });
        this.fetchOptions(id, this.printConfigurationError, index, 'configuration-exception')
    };

    popUpInfo = (id, index) => {
        this.fetchOptions(id, this.showRequestModal, index, 'options')
    };

    printInfo = (info, index) => {
        this.setState({statsArray: getStats(info)});
        this.openList(index)
    };

    printUploadError = (info, index) => {
        this.setState({uploadError_Message: info});
        this.openList(index);

    };

    printConfigurationError = (info, index) => {
        this.setState({configurationError_Message: info}, () => {
        });
        this.openList(index)
    };

    //Determine if list should be red green or neutral color;
    renderColor = (item) => {
        if (item.skipped) return "inactive";
        else if (!item.thrownUploadException && !item.thrownConfigurationException && item.duration !== 0) return 'positive';
        else if (item.thrownUploadException || item.thrownConfigurationException) return 'negative';
        else return ''
    };

    resizePagination = () => {
        let x = window.matchMedia("(max-width: 600px)");
        if (x.matches) {
            this.setState({paginationOptions: {boundaryRange: 0, showPreviousAndNextNav: false, siblingRange: 0}})
        } else {
            this.setState({paginationOptions: {boundaryRange: 10, showPreviousAndNextNav: true, siblingRange: 1}})
        }
    };

    fetchStatus = (id, index) => {
        let self = this;
        // Open list which is processing
        this.setState({activeConfiguration: id});
        fetch(process.env.REACT_APP_API_BASE_URL + '/' + id + '/status', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }).then(function (response) {
            if (!response.ok) {
                console.log('error', response);
                throw new Error(response.statusText)
            }
            return response.json()
        }).then(
            data => {
                console.log(data);
                self.setState({configurationStatus: data.status, configurationCaption: data.caption})
                let status = data.status;
                if (status === 'CONFIGURATION_FAILED') {
                    self.setState({
                        totalPercentage: 0,
                    });
                    alert("CONFIGURATION_FAILED");
                }
                if (status === 'QUEUED') {
                    self.setState({
                        totalPercentage: 25,
                    })
                } else if (status === 'PROCESSING') {
                    self.setState({totalPercentage: 60, subPercentage: data.progress})
                } else if (status === 'FINISHED') {
                    self.setState({totalPercentage: 100, subPercentage: data.progress})
                }
            },
        ).catch(error => console.log(error.body))
    };

    componentDidMount() {
        this.fetchConfig();
        this.setState({loadingEntries: true});
        this.interval = setInterval(() => {
            this.fetchConfig();
            return this.setState({time: Date.now()})
        }, 5000);
        this.resizePagination();
        window.addEventListener("resize", this.resizePagination);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener("resize", this.resizePagination);
    }

    render() {
        const {
            openRetryModal, totalPageNumber, openSkipModal, showRequest, pageNumber, configurations, activeIndex,
            paginationOptions, loadingEntries, activeConfiguration, configurationStatus, configurationCaption, subPercentage, totalPercentage
        } = this.state;

        return (
            <div className="dashboard-container">
                <Segment>
                    <Button primary style={{margin: '0.5em'}} onClick={() => this.fetchConfig()}> REFRESH (Last
                        refreshed {new Date(this.state.time).toISOString().slice(11, -1)}) </Button>
                    <Grid.Column>
                        <Pagination
                            activePage={pageNumber}
                            onPageChange={this.handlePaginationChange}
                            totalPages={totalPageNumber}
                            showEllipsis={true}
                            boundaryRange={paginationOptions.boundaryRange}
                            showFirstAndLastNav={true}
                            showPreviousAndNextNav={paginationOptions.showPreviousAndNextNav}
                            siblingRange={paginationOptions.siblingRange}
                        />
                    </Grid.Column>
                </Segment>
                {/*{!loading ? <Spinner/> : ""}*/}


                <table className="dashboard-table">
                    <thead className="dashboard-header">
                    <tr>
                        <th>ID</th>
                        <th>Project Name</th>
                        <th>Duration</th>
                        <th>Request Date</th>
                        <th>Request</th>
                        {/*<th>Exports</th>*/}
                        <th>Actions</th>
                        <th>Configuration Exception</th>
                        <th>Upload Exception</th>
                        <th>Upload Status</th>
                        <th>Source</th>
                    </tr>
                    </thead>
                    {loadingEntries ?
                        (<tbody>
                            <tr>
                                <td colSpan={10}><Spinner/></td>
                            </tr>
                            </tbody>
                        )
                        :
                        (
                            <tbody>
                            {configurations.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        <tr className={`dashboard-list-wrapper ${this.renderColor(item)}`}>
                                            {/*Show disabled ID if item is skipped*/}
                                            <td onClick={item.skipped ? null : () => this.showInfo(item.id, index)}
                                                className={item.skipped ? "inactive" : "hover"}>
                                                <p><FontAwesomeIcon
                                                    icon={faInfoCircle}/> {item.id}</p>
                                            </td>
                                            {item.queuePosition === 0 && item.finished === null ? this.fetchStatus(item.id) : null}
                                            <td>
                                                <p>{item.project}</p>
                                                {/*{console.log(item)}*/}
                                            </td>
                                            <td>
                                                {item.exceptionThrown ?
                                                    <p>
                                                        <Icon name='attention'/>
                                                        {item.duration}
                                                    </p>
                                                    :
                                                    <p>
                                                        {item.duration}
                                                    </p>}
                                            </td>
                                            <td>
                                                <p>{item.created}</p>
                                            </td>
                                            <td>
                                                <div className="dashboard-button-wrapper">
                                                    <CustomButton buttonType="regular" onClick={() => {
                                                        this.popUpInfo(item.id, index)
                                                    }} icon={faSearch} disabled={item.skipped}/>
                                                    <CopyToClipboard text={item.options}
                                                                     onCopy={() => this.setState({copied: true})}>
                                                        <CustomButton buttonType="regular" icon={faPaste}
                                                                      disabled={item.skipped}/>
                                                    </CopyToClipboard>
                                                </div>
                                            </td>
                                            <td>
                                                <CustomButton onClick={() => {
                                                    this.getExports(item.id, item.projectName)
                                                }} popup="Download this entry" buttonType="positive"
                                                              // downloading={this.state.downloading.includes(item.id)}
                                                              icon={faDownload}/>
                                            </td>
                                            <td>
                                                <div className="dashboard-button-wrapper">
                                                    <CustomButton
                                                        onClick={this.showSkipModal(item.id, 'SkippedThroughDashboard',
                                                            'Are you sure you want to skip this export?')}
                                                        popup="Skip this entry" buttonType="caution"
                                                        icon={faStepForward} disabled={item.skipped}/>
                                                    <CustomButton
                                                        onClick={this.showRetryModal(item.id, false, false,
                                                            'Are you sure you want to retry this export without a revit file? ' +
                                                            'This will update the files displayed on any dashboards linked to the database, ' +
                                                            'which may result in a signed contract being updated')}
                                                        buttonType="default" icon={faRedo}/>
                                                </div>
                                            </td>
                                            <td>
                                                {item.thrownConfigurationException &&
                                                <div className="dashboard-button-wrapper">
                                                    <CustomButton
                                                        disabled={item.skipped}
                                                        onClick={() => {
                                                            this.showConfigurationError(item.id, index)
                                                        }} buttonType="negative" icon={faInfoCircle}/>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                {item.thrownUploadException &&
                                                <div className="dashboard-button-wrapper">
                                                    <CustomButton popup="Click to see configuration errors"
                                                                  buttonType="negative"
                                                                  disabled={item.skipped}
                                                                  onClick={() => {
                                                                      this.showUploadError(item.id, index)
                                                                  }}
                                                                  icon={faInfoCircle}/>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                {activeConfiguration === item.id ? <p>{configurationStatus}</p> : null}
                                            </td>
                                            <td>
                                                <p>{item.source}</p>
                                            </td>
                                        </tr>
                                        <tr className={`dashboard-table-message-wrapper ${this.renderColor(item)} ${activeIndex ===
                                        index || activeConfiguration === item.id ? 'open' : ''}`}>
                                            <td colSpan={10}>
                                                {activeIndex === index ? (
                                                    (() => {
                                                        switch (this.state.informationState) {
                                                            case 'uploadError':
                                                                return <Message negative>
                                                                    <li>{this.state.uploadError_Message.storageProvider}</li>
                                                                    <li>{this.state.uploadError_Message.message}</li>
                                                                    <li>{this.state.uploadError_Message.folderType}</li>
                                                                    <li>{this.state.uploadError_Message.fileType}</li>
                                                                </Message>;
                                                            case 'configurationError':
                                                                return <Message negative className="heavy-error">
                                                                    <li>{this.state.configurationError_Message.type}</li>
                                                                    <li>{this.state.configurationError_Message.message}</li>
                                                                    <li>{this.state.configurationError_Message.stackTrace}</li>
                                                                </Message>;
                                                            case 'info':
                                                                return <Message info>
                                                                    <ul>
                                                                        {this.state.statsArray === null ?
                                                                            (<li> This file contains no
                                                                                blocks </li>) :
                                                                            (this.state.statsArray.map(item => {
                                                                                return <li>{item}</li>
                                                                            }))}
                                                                    </ul>
                                                                </Message>;
                                                            default:
                                                                return null
                                                        }
                                                    })()) : null
                                                }
                                                {/*Show loading bar and caption of the currently processing configuration*/}
                                                {activeConfiguration === item.id ? (
                                                        <React.Fragment>
                                                            <Divider/>
                                                            <Progress percent={totalPercentage} label={"Total Progress"}
                                                                      indicating/>
                                                            <Divider/>
                                                            <Progress percent={subPercentage} label={configurationCaption}
                                                                      indicating/>
                                                            <Divider/>
                                                        </React.Fragment>)
                                                    : null}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            })}
                            </tbody>)}
                </table>

                <Modal
                    size={'mini'}
                    open={openRetryModal}
                    onClose={this.closeRetryModal}>
                    <Modal.Content>
                        < p> {this.state.message}
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={
                            this.closeRetryModal}>No</Button>
                        <Button positive icon='checkmark' labelPosition='right' content='Yes'
                                onClick={() =>
                                    this.handleRetry(this.state.id, this.state.exportRevitFile, this.state.asNewRequest)
                                }/>
                    </Modal.Actions>
                </Modal>

                <Modal size={'mini'} open={openSkipModal} onClose={this.closeSkipModal}>
                    <Modal.Content>
                        <p>{this.state.message}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={
                            this.closeSkipModal}>No</Button>
                        <Button positive icon='checkmark' labelPosition='right' content='Yes'
                                onClick={() =>
                                    this.handleSkip(this.state.id, this.state.skipMessage)
                                }/>
                    </Modal.Actions>
                </Modal>

                <Modal size={'large'} open={showRequest} onClose={this.closeRequestModal}>
                    <Modal.Content>
                        <pre>{this.state.json}</pre>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={
                            this.closeRequestModal}>Close</Button>
                    </Modal.Actions>
                </Modal>

                <Modal size={'large'} open={this.state.download.open} onClose={() => {this.closeDownload()}}>
                    <Modal.Content>
                        {this.state.download.files.map((data)=> {
                            return <div>
                                <a href={`https://vwhive.blob.core.windows.net/exports/${data.id}`}>{data.name}</a>
                            </div>
                        })}
                        {console.log(this.state.download.files)}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={
                            this.closeDownload}>Close</Button>
                    </Modal.Actions>
                </Modal>

                <Segment>
                    <Button primary style={{margin: '0.5em'}} onClick={() => this.fetchConfig()}> REFRESH (Last
                        refreshed {new Date(this.state.time).toISOString().slice(11, -1)}) </Button>
                    <Grid.Column>
                        <Pagination
                            activePage={pageNumber}
                            onPageChange={this.handlePaginationChange}
                            totalPages={totalPageNumber}
                            showEllipsis={true}
                            boundaryRange={paginationOptions.boundaryRange}
                            showFirstAndLastNav={true}
                            showPreviousAndNextNav={paginationOptions.showPreviousAndNextNav}
                            siblingRange={paginationOptions.siblingRange}
                        />
                    </Grid.Column>
                </Segment>
            </div>
        )
    }
}

export default Home
